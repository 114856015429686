// LiveStreamBadge.js
import { connect } from 'react-redux';

import { FEATURE_LIVESTREAM_CARD_FUNDING_PROGRESS_RATIO } from '../RemoteConfigKeys.js';

import LiveStreamBadge from '../component/LiveStreamBadge.jsx';
import getTimestampOffset, {
  Accuracy,
} from '../selector/getTimestampOffset.js';
import getLiveStreamData from '../selector/getLiveStreamData.js';
import getGoalData from '../selector/getGoalData.js';
import getPrivateShowStatus from '../selector/getPrivateShowStatus.js';
import getRemoteConfigData from '../selector/getRemoteConfigData.js';

const mapStateToProps = (state, { streamId }) => {
  const privateShowStatus = getPrivateShowStatus(state, streamId);
  const timestampOffsetSeconds = getTimestampOffset(state, Accuracy.SECOND);
  const fundingId = getLiveStreamData(
    state,
    [streamId, 'privateShow'],
    'fundingId'
  );
  const fundingExp = getGoalData(state, [fundingId], 'exp');
  const streamStatus = getLiveStreamData(state, [streamId], 'status');
  const exclusivePrivateStatus = getLiveStreamData(
    state,
    [streamId, 'exclusivePrivate'],
    'status'
  );
  const fundingTarget = getGoalData(state, [fundingId, 'levels', 0], 'target');
  const fundingProgress = getGoalData(state, [fundingId], 'progress');
  const isFundingProgressRatioEnabled =
    getRemoteConfigData(
      state,
      FEATURE_LIVESTREAM_CARD_FUNDING_PROGRESS_RATIO
    ) === 1;
  return {
    privateShowStatus,
    exclusivePrivateStatus,
    streamStatus,
    timestampOffsetSeconds,
    fundingExp,
    fundingTarget,
    fundingProgress,
    isFundingProgressRatioEnabled,
  };
};

export default connect(
  mapStateToProps,
  null,
  // equals to `{ ...ownProps, ...stateProps, ...dispatchProps }`
  // ref: https://github.com/reduxjs/react-redux/blob/v7.1.0/src/connect/mergeProps.js
  null,
  { forwardRef: true } // Required by WithIntersectionObserver
)(LiveStreamBadge);
