// LiveStreamBadge.jsx
import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { withTranslation } from 'react-i18next';

import Animator from '../component/Animator.jsx';
import {
  LIVESTREAM_TYPE_LIVE_BADGE,
  LIVESTREAM_TYPE_STATUS_BADGE,
  LIVESTREAM_TYPE_DOT,
  LIVESTREAM_CARD_STATUS_BADGE,
} from '../resource/userStatusIndicatorConstants.js';
import getCurrentUnixTimestamp from '../resource/getCurrentUnixTimestamp.js';
import {
  ExclusivePrivateStatus,
  PRESET_PREVIEW,
  PRESET_SD,
  privateShowStatus as ShowStatusConstant,
} from '../resource/liveStreamConstants.js';
import { TranslationNamespace } from '../resource/translationNamespace.js';
import { textColor, color } from '../style/variables.js';
import OpacityBreath from '../style/OpacityBreath.js';
import media from '../style/media.js';
import { formatTimeStringBySec } from '../resource/formatTimeString.js';
import LivestreamDotResource from '../../img/ic_livestream.gif';
import LivestreamPublicResource from '../../img/ic_private_view_xs.svg';
import TicketFillResource from '../../img/ic-ticket-fill.svg';

const Mode = {
  PREVIEW: 'preview',
  GROUP_PRIVATE: 'group-private',
  EXCLUSIVE_PRIVATE: 'exclusive-private',
  PRIVATE_SHOW: 'private-show',
};
const BackgroundColor = {
  [Mode.PREVIEW]: css`
    background-color: ${color.neutral[20]};
  `,
  [Mode.GROUP_PRIVATE]: css`
    background-image: linear-gradient(
      135deg,
      rgba(186, 57, 243, 0.8) 0%,
      rgba(84, 32, 162, 0.8) 100%
    );
  `,
  [Mode.EXCLUSIVE_PRIVATE]: css`
    background-image: linear-gradient(
      90deg,
      #00d2c1 0%,
      #5d82bc 48.44%,
      #8e37d7 100%
    );
  `,
  [Mode.PRIVATE_SHOW]: css`
    background-image: linear-gradient(
      90deg,
      rgba(253, 86, 150, 0.8) 0%,
      rgba(255, 193, 0, 0.8) 100%
    );
  `,
};

export class LiveStreamBadge extends React.PureComponent {
  getFormattedSeconds = ({ target }) => {
    const { timestampOffsetSeconds } = this.props;
    const now = getCurrentUnixTimestamp({
      offsetSeconds: timestampOffsetSeconds,
    });
    return formatTimeStringBySec(Math.max(0, target - now));
  };
  render() {
    const {
      type,
      privateShowStatus,
      exclusivePrivateStatus,
      streamStatus,
      fundingExp,
      fundingTarget,
      fundingProgress,
      size,
      t,
      isFluid,
      isFundingProgressRatioEnabled,
    } = this.props;
    const isPrivateShowPerforming = [
      ShowStatusConstant.PREPARING,
      ShowStatusConstant.PERFORMING,
    ].includes(privateShowStatus);
    const isPrivateShowFunding = [
      ShowStatusConstant.FUNDING,
      ShowStatusConstant.FUNDING_FINAL_CALL,
    ].includes(privateShowStatus);
    const isExclusivePrivatePerforming = [
      ExclusivePrivateStatus.PERFORMING,
      ExclusivePrivateStatus.PREPARING,
    ].includes(exclusivePrivateStatus);

    if (LIVESTREAM_TYPE_DOT === type) {
      return (
        <LivestreamDot>
          <Dot
            src={
              typeof LivestreamDotResource === 'object'
                ? LivestreamDotResource.src
                : LivestreamDotResource
            }
            alt="live streaming"
            size={size}
          />
        </LivestreamDot>
      );
    }
    if (type === LIVESTREAM_TYPE_LIVE_BADGE) {
      return <LiveTextBadge size={size}>LIVE</LiveTextBadge>;
    }
    if (LIVESTREAM_CARD_STATUS_BADGE === type) {
      const ns = TranslationNamespace.LIVESTREAM;
      const displayTitle = isExclusivePrivatePerforming ? (
        t('title_stream_exclusive', {
          ns,
        })
      ) : isPrivateShowFunding ? (
        isFundingProgressRatioEnabled ? (
          t('funded', {
            ns,
            percent: Math.round((fundingProgress / fundingTarget) * 100) || 0,
          })
        ) : (
          <>
            <img src={TicketFillResource} alt="ticket" />
            {t('funding_remaining', {
              ns,
              amount: Math.max(0, fundingTarget - fundingProgress),
            })}
          </>
        )
      ) : isPrivateShowPerforming || PRESET_SD === streamStatus ? (
        t('performing', {
          ns,
        })
      ) : PRESET_PREVIEW === streamStatus ? (
        t('badge_live_public', {
          ns,
        })
      ) : (
        ''
      );
      if (!displayTitle) return null;
      return (
        <LivestreamCardStatusBadge
          type={
            isExclusivePrivatePerforming
              ? Mode.EXCLUSIVE_PRIVATE
              : isPrivateShowFunding ||
                  isPrivateShowPerforming ||
                  PRESET_SD === streamStatus
                ? Mode.GROUP_PRIVATE
                : PRESET_PREVIEW === streamStatus
                  ? Mode.PREVIEW
                  : ''
          }
        >
          {displayTitle}
        </LivestreamCardStatusBadge>
      );
    }
    if (type === LIVESTREAM_TYPE_STATUS_BADGE) {
      if (isPrivateShowPerforming) {
        return (
          <ShowStreamTextBadge $isFluid={isFluid}>
            <img src={LivestreamPublicResource} alt="streaming badge" />
            {t('badge_live_performing', {
              ns: TranslationNamespace.LIVESTREAM,
            })}
          </ShowStreamTextBadge>
        );
      }
      if (isPrivateShowFunding) {
        return (
          <FundingStreamTextBadge $isFluid={isFluid}>
            <img src={LivestreamPublicResource} alt="streaming badge" />
            {t('badge_live_funding', {
              ns: TranslationNamespace.LIVESTREAM,
            })}
            <Animator
              fps={1}
              render={() => this.getFormattedSeconds({ target: fundingExp })}
            />
          </FundingStreamTextBadge>
        );
      }
      if (isExclusivePrivatePerforming) {
        return (
          <ExclusivePrivateTextBadge $isFluid={isFluid}>
            <img src={LivestreamPublicResource} alt="" />
            {t('title_stream_exclusive', {
              ns: TranslationNamespace.LIVESTREAM,
            })}
          </ExclusivePrivateTextBadge>
        );
      }
      if (PRESET_SD === streamStatus) {
        return (
          <PrivateStreamTextBadge $isFluid={isFluid}>
            <img src={LivestreamPublicResource} alt="streaming badge" />
            {t('title_stream_private', {
              ns: TranslationNamespace.LIVESTREAM,
            })}
          </PrivateStreamTextBadge>
        );
      }
      return (
        <StreamTextBadge $isFluid={isFluid}>
          <img src={LivestreamPublicResource} alt="streaming badge" />
          {t('badge_live_public', {
            ns: TranslationNamespace.LIVESTREAM,
          })}
        </StreamTextBadge>
      );
    }
    return null;
  }
}

LiveStreamBadge.propTypes = {
  type: PropTypes.string,
  privateShowStatus: PropTypes.string,
  exclusivePrivateStatus: PropTypes.string,
  streamStatus: PropTypes.string,
  timestampOffsetSeconds: PropTypes.number,
  fundingExp: PropTypes.number,
  fundingTarget: PropTypes.number,
  fundingProgress: PropTypes.number,
  size: PropTypes.number,
  isFluid: PropTypes.bool,
  isFundingProgressRatioEnabled: PropTypes.bool,
  t: PropTypes.func.isRequired,
};

LiveStreamBadge.defaultProps = {
  type: LIVESTREAM_TYPE_DOT,
  privateShowStatus: null,
  exclusivePrivateStatus: null,
  streamStatus: null,
  isFluid: false,
  isFundingProgressRatioEnabled: false,
  timestampOffsetSeconds: 0,
  fundingExp: 0,
  fundingTarget: 0,
  fundingProgress: 0,
  size: 0,
};

const LivestreamDot = styled.div`
  display: flex;
  align-items: center;
`;

const Dot = styled.img.attrs(({ size }) => ({
  width: `${size}px`,
  height: `${size}px`,
}))``;

const LiveTextBadge = styled.div.attrs(({ size }) => ({
  style: {
    lineHeight: `${size}px`,
  },
}))`
  border-radius: 2px;
  padding: 0px 4px;
  font-size: 12px;
  font-weight: 600;
  color: ${textColor.white};
  white-space: nowrap;
  background-image: linear-gradient(
    117deg,
    rgb(164, 69, 179) 5%,
    rgb(212, 24, 114) 55%,
    rgb(255, 0, 102)
  );
`;

const StreamTextBadge = styled.div`
  position: relative;
  padding: 0px 4px;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  color: ${color.neutral[100]};
  background-image: linear-gradient(
    136deg,
    rgba(164, 69, 179, 0.8) 3.79%,
    rgba(212, 24, 114, 0.8) 48.03%,
    rgba(255, 0, 102, 0.8) 97.19%
  );
  > img {
    ${OpacityBreath({ duration: 0.5 })};
    margin-right: 4px;
    width: 16px;
    height: 16px;
    object-fit: contain;
  }
  ${media.tablet`
    font-size: 12px;
    >img {
      width: 12px;
      height: 12px;
    }
  `};
  ${({ $isFluid }) =>
    $isFluid
      ? css`
          display: flex;
        `
      : css`
          border-radius: 2px;
          display: inline-flex;
        `};
`;

const PrivateStreamTextBadge = styled(StreamTextBadge)`
  ${BackgroundColor[Mode.GROUP_PRIVATE]};
`;

const FundingStreamTextBadge = styled(StreamTextBadge)`
  ${BackgroundColor[Mode.PRIVATE_SHOW]};
`;

const ShowStreamTextBadge = styled(StreamTextBadge)`
  ${BackgroundColor[Mode.PRIVATE_SHOW]};
`;

const ExclusivePrivateTextBadge = styled(StreamTextBadge)`
  ${BackgroundColor[Mode.EXCLUSIVE_PRIVATE]};
`;

const LivestreamCardStatusBadge = styled.div`
  ${({ type }) => BackgroundColor[type] ?? ''};
  border-top-right-radius: 8px;
  border-bottom-left-radius: 8px;
  padding: 0px 8px;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.75;
  color: ${color.neutral[100]};
  ${media.tablet`
    font-size: 12px;
    line-height: 1.8;
  `};
  > img {
    margin-right: 2px;
    width: 20px;
    height: 20px;
    ${media.tablet`
      width: 16px;
      height: 16px;
    `};
  }
`;

export default withTranslation('translation', {
  withRef: true,
})(LiveStreamBadge);
